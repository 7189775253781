import React from 'react';
import './Editmentormodalbox.css';
import { useTriggers } from '../../hooks/navigation';
import useEditMentorTabs from '../../hooks/api/mentors/useEditMentorTabs';
import {
  MentorSettings,
  MentorLLM,
  MentorPrompts,
  MentorDataset,
  MentorEmbed,
  MentorTools,
  MentorHistory,
} from '../../modules/mentors/edit';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Editmentormodalbox = (props) => {
  const { t } = useTranslation();
  const { handleModalCloseClick } = useTriggers();
  const { tab, tabs, setTab } = useEditMentorTabs();
  return (
    <div className="modal edit-mentor-modal">
      <div className="w-layout-vflex modal-container prompt-gallery-container edit-mentor-modal-container">
        <div className="w-layout-hflex modal-header">
          <h3 className="modal-header-title">{t('Edit Mentor')}</h3>
          <a className="default-btn prompt-add-btn w-button" href="#">
            {t(' + Add New Prompt')}
          </a>
          <div
            className="modal-close-wrapper"
            data-w-id="cf15a9d9-a59b-1c17-f2d2-4a35c02cd588"
            onClick={handleModalCloseClick}
            prop-events-value-onclick="handleModalCloseClick"
          >
            <img alt="" loading="lazy" src="/images/close_1close.png" />
          </div>
        </div>
        <div className="w-layout-vflex modal-body all-whiten-in-dark-mode">
          <div className="w-layout-hflex menu-container data-source-types-menu-block">
            {tabs?.map((item) => (
              <a
                className={`menu-element-block w-inline-block ${item.key === tab ? 'current' : ''}`}
                data-w-id="ca621093-7a00-bd53-ecc7-48d2e28e9363"
                key={item.key}
                onClick={() => setTab(item.key)}
                href="#"
              >
                <div className="text-block-47 active-text">{item.title}</div>
                <div
                  className={`menu-element-bt-border ${item.key === tab ? 'active-element-bt' : ''} `}
                ></div>
              </a>
            ))}
          </div>
          <div className="w-layout-vflex edit-mentor-tabs-container">
            {tab === 'settings' && <MentorSettings />}
            {tab === 'llm' && <MentorLLM />}
            {tab === 'prompts' && <MentorPrompts />}
            {tab === 'datasets' && <MentorDataset />}
            {tab === 'embed' && <MentorEmbed />}
            {tab === 'tools' && <MentorTools />}
            {tab === 'history' && <MentorHistory />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editmentormodalbox;
