import useMentorEmbed from '../../../hooks/api/mentors/useMentorEmbed';
import { useSelector } from 'react-redux';
import MentorEmbedWidget from './embed-widget';
import {
  handleLayoutsWithCustomTooltipsJS,
  userIsOnTrial,
} from '../../../utils/shared';
import SubmitBtn from '../../../components/SubmitBtn/SubmitBtn';
import { useDebounce } from '../../../hooks/useDebounce';
import { useEffect } from 'react';
import { useTriggers } from '../../../hooks/navigation';

export default function MentorEmbed() {
  const mentor = useSelector((state) => state.mentors.edit.mentor);
  const auth = useSelector((state) => state.auth.data);
  const { modals } = useSelector((state) => state.navigation.data);
  const {
    handleSubmit,
    handleChange,
    handleCheckChange,
    submitting,
    mentorToEmbed,
    settings,
    handleMetaDataCheckChange,
    redirectToken,
  } = useMentorEmbed(mentor);
  const { handleOpenFreeTrialModal } = useTriggers();

  useDebounce(handleLayoutsWithCustomTooltipsJS, 200, []);

  return (
    <>
      <div
        className="w-layout-vflex edit-mentor-interface-tab"
        style={{ display: 'flex' }}
      >
        <div className="course-creator-block">
          <div className="w-layout-hflex course-creator-title-block">
            <h4 className="course-creator-title">Interface Design</h4>
          </div>
          <div className="w-layout-hflex flex-block-29">
            <div
              className="course-creator-content"
              style={{ rowGap: settings?.allow_anonymous ? '36px' : '23px' }}
            >
              <div
                className="w-layout-vflex subsection-flex-block"
                style={{ display: 'none' }}
              >
                <div className="subsection-title">Logo</div>
                <div className="w-layout-hflex flex-block-78">
                  <div className="form-block-10 w-form">
                    <form
                      id="wf-form-Agent-Settings"
                      name="wf-form-Agent-Settings"
                      data-name="Agent Settings"
                      method="get"
                      className="file-upload-block"
                      data-wf-page-id="650305782bc61751e5765214"
                      data-wf-element-id="fe6c5d06-6f55-f78b-b894-7ea5cb4f0891"
                      aria-label="Agent Settings"
                    >
                      <div className="w-embed">
                        <input type="file" />
                      </div>
                    </form>
                    <div
                      className="w-form-done"
                      tabIndex="-1"
                      role="region"
                      aria-label="Agent Settings success"
                    >
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div
                      className="w-form-fail"
                      tabIndex="-1"
                      role="region"
                      aria-label="Agent Settings failure"
                    >
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-layout-vflex subsection-flex-block hide">
                <div className="subsection-title">Name</div>
                <div className="w-layout-hflex flex-block-78">
                  <div className="form-block-10 w-form">
                    <form
                      id="wf-form-Agent-Settings"
                      name="wf-form-Agent-Settings"
                      data-name="Agent Settings"
                      method="get"
                      className="form-6"
                      data-wf-page-id="650305782bc61751e5765214"
                      data-wf-element-id="438cbaae-22ee-02d1-d824-ce67c6caa23c"
                      aria-label="Agent Settings"
                    >
                      <input
                        className="form-input secret-key-form-input edit-mentor-settings-form-input black-bg-white-color-form-field interface-mentor-name-input w-input"
                        maxLength="256"
                        name="organisation-name-4"
                        data-name="Organisation Name 4"
                        placeholder="Mentor Name"
                        custom-value="Personal"
                        type="email"
                        id="organisation-name-4"
                      />
                    </form>
                    <div
                      className="w-form-done"
                      tabIndex="-1"
                      role="region"
                      aria-label="Agent Settings success"
                    >
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div
                      className="w-form-fail"
                      tabIndex="-1"
                      role="region"
                      aria-label="Agent Settings failure"
                    >
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-layout-vflex subsection-flex-block">
                <div className="subsection-title">Welcome Text</div>
                <div className="w-layout-hflex flex-block-78">
                  <div className="form-block-10 w-form">
                    <form
                      id="wf-form-Agent-Settings"
                      name="wf-form-Agent-Settings"
                      data-name="Agent Settings"
                      method="get"
                      className="form-6"
                      data-wf-page-id="650305782bc61751e5765214"
                      data-wf-element-id="00c67c66-c5f2-ad98-9cdf-ef228b24e3f8"
                      aria-label="Agent Settings"
                    >
                      <textarea
                        value={settings.initial_message}
                        onChange={handleChange}
                        required
                        rows="2"
                        name="initial_message"
                        data-name="initial_message"
                        placeholder="Welcome Text"
                        maxLength="5000"
                        id="field-9"
                        className="textarea-4 black-bg-white-color-form-field small-text-area interface-welcome-textarea w-input"
                      ></textarea>
                    </form>
                    <div
                      className="w-form-done"
                      tabIndex="-1"
                      role="region"
                      aria-label="Agent Settings success"
                    >
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div
                      className="w-form-fail"
                      tabIndex="-1"
                      role="region"
                      aria-label="Agent Settings failure"
                    >
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-layout-vflex subsection-flex-block">
                <div className="subsection-title"> Default Prompt</div>
                <div className="w-layout-hflex flex-block-78">
                  <div className="form-block-10 w-form">
                    <form
                      id="wf-form-Agent-Settings"
                      name="wf-form-Agent-Settings"
                      data-name="Agent Settings"
                      method="get"
                      className="form-6"
                      data-wf-page-id="650305782bc61751e5765214"
                      data-wf-element-id="4621aa8a-93e5-b97c-f17d-80c50ec775e5"
                      aria-label="Agent Settings"
                    >
                      <textarea
                        placeholder="Prompt Text"
                        maxLength="5000"
                        id="field-9"
                        value={settings.suggested_message}
                        required
                        rows="2"
                        onChange={handleChange}
                        name="suggested_message"
                        className="textarea-4 black-bg-white-color-form-field small-text-area mentor-default-prompt1-textarea min-height-augmented w-input"
                      ></textarea>
                    </form>
                    <div
                      className="w-form-done"
                      tabIndex="-1"
                      role="region"
                      aria-label="Agent Settings success"
                    >
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div
                      className="w-form-fail"
                      tabIndex="-1"
                      role="region"
                      aria-label="Agent Settings failure"
                    >
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-layout-vflex subsection-flex-block hide">
                <div className="subsection-title">Theme</div>
                <div className="w-layout-hflex flex-block-78">
                  <div className="form-block-10 w-form">
                    <form
                      id="wf-form-Agent-Settings"
                      name="wf-form-Agent-Settings"
                      data-name="Agent Settings"
                      method="get"
                      className="form-6"
                      data-wf-page-id="650305782bc61751e5765214"
                      data-wf-element-id="3cc34d8f-62a0-b2dc-3723-e1f2b9759509"
                      aria-label="Agent Settings"
                    >
                      <select
                        id="theme-4"
                        name="theme-4"
                        data-name="Theme 4"
                        className="form-select w-select"
                      >
                        <option value="light">Light</option>
                        <option value="Dark">Dark</option>
                      </select>
                    </form>
                    <div
                      className="w-form-done"
                      tabIndex="-1"
                      role="region"
                      aria-label="Agent Settings success"
                    >
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div
                      className="w-form-fail"
                      tabIndex="-1"
                      role="region"
                      aria-label="Agent Settings failure"
                    >
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*<div className="w-layout-hflex flex-block-61">
                <div className="w-layout-vflex subsection-flex-block">
                  <div className="subsection-title">Primary Color</div>
                  <div className="w-layout-hflex flex-block-78">
                    <div className="form-block-10 w-form">
                      <form
                        id="wf-form-Agent-Settings"
                        name="wf-form-Agent-Settings"
                        data-name="Agent Settings"
                        method="get"
                        className="form-6"
                        data-wf-page-id="650305782bc61751e5765214"
                        data-wf-element-id="fe6c5d06-6f55-f78b-b894-7ea5cb4f08c6"
                        aria-label="Agent Settings"
                      >
                        <div className="div-block-39">
                          <div className="w-embed">
                            <input
                              value={settings?.metadata?.primary_color}
                              onChange={handleMetadataChange}
                              name="primary_color"
                              style={{
                                width: '100%',
                                background: '#eee',
                                border: 'solid 0.95px #a7a7a7',
                              }}
                              type="color"
                            />
                          </div>
                        </div>
                      </form>
                      <div
                        className="w-form-done"
                        tabIndex="-1"
                        role="region"
                        aria-label="Agent Settings success"
                      >
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div
                        className="w-form-fail"
                        tabIndex="-1"
                        role="region"
                        aria-label="Agent Settings failure"
                      >
                        <div>
                          Oops! Something went wrong while submitting the form.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-layout-vflex subsection-flex-block">
                  <div className="subsection-title">Secondary Color</div>
                  <div className="w-layout-hflex flex-block-78">
                    <div className="form-block-10 w-form">
                      <form
                        id="wf-form-Agent-Settings"
                        name="wf-form-Agent-Settings"
                        data-name="Agent Settings"
                        method="get"
                        className="form-6"
                        data-wf-page-id="650305782bc61751e5765214"
                        data-wf-element-id="fe6c5d06-6f55-f78b-b894-7ea5cb4f08d4"
                        aria-label="Agent Settings"
                      >
                        <div className="div-block-39">
                          <div className="w-embed">
                            <input
                              value={settings?.metadata?.secondary_color}
                              onChange={handleMetadataChange}
                              name="secondary_color"
                              style={{
                                width: '100%',
                                background: '#eee',
                                border: 'solid 0.95px #a7a7a7',
                              }}
                              type="color"
                            />
                          </div>
                        </div>
                      </form>
                      <div
                        className="w-form-done"
                        tabIndex="-1"
                        role="region"
                        aria-label="Agent Settings success"
                      >
                        <div>Thank you! Your submission has been received!</div>
                      </div>
                      <div
                        className="w-form-fail"
                        tabIndex="-1"
                        role="region"
                        aria-label="Agent Settings failure"
                      >
                        <div>
                          Oops! Something went wrong while submitting the form.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-layout-vflex subsection-flex-block hide">
                <div className="subsection-title">Align Mentor Bubble</div>
                <div className="w-layout-hflex flex-block-78">
                  <div className="form-block-10 w-form">
                    <form
                      id="wf-form-Agent-Settings"
                      name="wf-form-Agent-Settings"
                      data-name="Agent Settings"
                      method="get"
                      className="form-6"
                      data-wf-page-id="650305782bc61751e5765214"
                      data-wf-element-id="fe6c5d06-6f55-f78b-b894-7ea5cb4f08e2"
                      aria-label="Agent Settings"
                    >
                      <select
                        id="theme-2"
                        name="theme-2"
                        data-name="Theme 2"
                        className="form-select w-select"
                      >
                        <option value="Right">Right</option>
                        <option value="Left">Left</option>
                      </select>
                    </form>
                    <div
                      className="w-form-done"
                      tabIndex="-1"
                      role="region"
                      aria-label="Agent Settings success"
                    >
                      <div>Thank you! Your submission has been received!</div>
                    </div>
                    <div
                      className="w-form-fail"
                      tabIndex="-1"
                      role="region"
                      aria-label="Agent Settings failure"
                    >
                      <div>
                        Oops! Something went wrong while submitting the form.
                      </div>
                    </div>
                  </div>
                </div>
              </div>*/}
              <div className="w-layout-vflex subsection-flex-block advanced-css-block">
                <div className="subsection-title">Advanced CSS</div>
                <div className="w-layout-hflex flex-block-78">
                  <div className="form-block-10 w-form">
                    <form
                      id="wf-form-Agent-Settings"
                      name="wf-form-Agent-Settings"
                      data-name="Agent Settings"
                      method="get"
                      className="form-6"
                      data-wf-page-id="650305782bc61751e5765214"
                      data-wf-element-id="3c779cfa-905e-5ebe-d1a0-190683d5ea2b"
                      aria-label="Agent Settings"
                    >
                      <textarea
                        placeholder="/* CSS */"
                        maxLength="5000"
                        rows="2"
                        id="custom_css"
                        value={settings?.custom_css}
                        onChange={handleChange}
                        name="custom_css"
                        className="textarea-4 black-bg-white-color-form-field min-height-reduced w-input"
                      ></textarea>
                    </form>
                  </div>
                </div>
              </div>

              <div className="w-layout-vflex subsection-flex-block advanced-css-block">
                <div className="w-layout-vflex flex-block-103">
                  <div
                    data-w-id="075318e9-3d9f-bc8b-7154-a61793b2951a"
                    className="w-layout-hflex flex-block-84 space-between has-custom-tooltip"
                  >
                    <div className="w-layout-hflex flex-block-104">
                      <div className="w-layout-hflex flex-block-105">
                        <div className="subsection-title">Anonymous</div>
                        <img
                          src="/images/alert-circle.svg"
                          loading="lazy"
                          height="20"
                          alt="This is the Free trial banner information icon"
                          className="context-aware-icon"
                        />
                      </div>
                    </div>
                    <div className="user-row-switcher-embed justify-start-flex w-embed">
                      <label className="user-switcher">
                        <input
                          type="checkbox"
                          name="allow_anonymous"
                          onChange={handleCheckChange}
                          checked={settings.allow_anonymous}
                        />
                        <span className="user-switcher-slider round"></span>
                      </label>
                    </div>
                    <div className="tooltip tooltip-embed-switcher anonymous">
                      Allows to embed the mentor without requiring users to log
                      in or sign up. This means that all messages will be
                      anonymous.
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`w-layout-vflex flex-block-103 ${settings?.allow_anonymous ? 'hidden' : ''}`}
              >
                <div
                  data-w-id="075318e9-3d9f-bc8b-7154-a61793b2951a"
                  style={{ width: '100%' }}
                >
                  <div className="w-layout-hflex flex-block-104">
                    <div className="w-layout-hflex flex-block-105 has-custom-tooltip">
                      <div className="subsection-title">Website URL</div>
                      <img
                        src="/images/alert-circle.svg"
                        loading="lazy"
                        height="20"
                        alt="This is the Free trial banner information icon"
                        className="context-aware-icon"
                      />
                      <div
                        className="tooltip tooltip-embed-switcher"
                        style={{ display: 'none' }}
                      >
                        The URL of the site where the mentor will be embedded.
                      </div>
                    </div>
                  </div>

                  <div
                    className="w-layout-hflex flex-block-78"
                    style={{ width: '100%', marginTop: '5px' }}
                  >
                    <div className="form-block-10 w-form">
                      <form
                        id="wf-form-Agent-Settings"
                        name="wf-form-Agent-Settings"
                        data-name="Agent Settings"
                        method="post"
                        className="form-6"
                        data-wf-page-id="66953e194f60e4f88496390e"
                        data-wf-element-id="fc87892e-81a2-2243-0dad-bf6f777e150a"
                      >
                        <input
                          className="text-field-6 w-input"
                          id="website"
                          maxLength="256"
                          name="website_url"
                          placeholder="Website URL"
                          type="url"
                          value={settings.website_url}
                          onChange={handleChange}
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              {!userIsOnTrial() && (
                <div className="w-layout-vflex subsection-flex-block advanced-css-block">
                  <div className="w-layout-vflex flex-block-103">
                    <div
                      data-w-id="075318e9-3d9f-bc8b-7154-a61793b29526"
                      className="w-layout-hflex flex-block-84 space-between has-custom-tooltip"
                    >
                      <div className="w-layout-hflex flex-block-104">
                        <div className="w-layout-hflex flex-block-105">
                          <div className="subsection-title">Context Aware</div>
                          <img
                            src="/images/alert-circle.svg"
                            loading="lazy"
                            height="20"
                            alt="This is the Free trial banner information icon"
                            className="context-aware-icon"
                          />
                        </div>
                      </div>
                      <div className="user-row-switcher-embed justify-start-flex w-embed">
                        <label className="user-switcher">
                          <input
                            disabled={submitting}
                            name="is_context_aware"
                            onChange={handleCheckChange}
                            checked={settings.is_context_aware}
                            type="checkbox"
                          />
                          <span className="user-switcher-slider round"></span>
                        </label>
                      </div>
                      <div className="tooltip tooltip-embed-switcher context-aware">
                        Allows to access the content of the page that embeds the
                        mentor. This helps the mentor gain context.
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="w-layout-vflex subsection-flex-block advanced-css-block">
                <div className="w-layout-vflex flex-block-103">
                  <div
                    data-w-id="075318e9-3d9f-bc8b-7154-a61793b29532"
                    className="w-layout-hflex flex-block-84 space-between has-custom-tooltip"
                  >
                    <div className="w-layout-hflex flex-block-104">
                      <div className="w-layout-hflex flex-block-105">
                        <div className="subsection-title">
                          Safety Disclaimer
                        </div>
                        <img
                          src="/images/alert-circle.svg"
                          loading="lazy"
                          height="20"
                          alt="This is the Free trial banner information icon"
                          className="context-aware-icon"
                        />
                      </div>
                    </div>
                    <div className="user-row-switcher-embed justify-start-flex w-embed">
                      <label className="user-switcher">
                        <input
                          name="safety_disclaimer"
                          onChange={handleMetaDataCheckChange}
                          type="checkbox"
                          disabled={submitting}
                          checked={settings.metadata.safety_disclaimer}
                        />
                        <span className="user-switcher-slider round"></span>
                      </label>
                    </div>
                    <div className="tooltip tooltip-embed-switcher safety-disclaimer">
                      Ensures that users are informed about potential risks
                      associated with using the mentor. This provides important
                      safety information to maintain a secure experience.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-layout-vflex course-creator-chatbot-block">
              <div
                className="ai-mentor-embed w-embed w-iframe"
                id="ai-mentor-embed-container"
              >
                <iframe
                  id="embed-mentor-preview"
                  src={`${window.location.origin}/platform/${auth?.tenant?.key}/${mentor?.slug}?mentor=${mentor?.name}&embed=true&internalPreview=true&tenant=${auth?.tenant?.key}&mode=anonymous`}
                  style={{
                    height: '100%',
                    minHeight: '580px',
                    width: '100%',
                    minWidth: '280px',
                  }}
                  frameBorder="0"
                ></iframe>
              </div>
              <div className="w-layout-hflex flex-block-76">
                <img
                  src="https://assets-global.website-files.com/650305782bc61751e5765211/650305782bc61751e576524c_ibl-logo-animated.gif"
                  loading="lazy"
                  alt=""
                  className="image-53"
                />
              </div>
            </div>
          </div>
          <div
            className="w-layout-hflex course-creator-bottom"
            style={{ justifyContent: 'end' }}
          >
            <SubmitBtn
              onClick={
                submitting || userIsOnTrial()
                  ? handleOpenFreeTrialModal
                  : handleSubmit
              }
              data-w-id="fe6c5d06-6f55-f78b-b894-7ea5cb4f093a"
              className="section-submit-btn"
              saving={submitting}
              label={'Embed Now'}
            />
          </div>
        </div>
      </div>

      {modals?.['embed-mentor-modal'] && (
        <MentorEmbedWidget
          mentor={mentorToEmbed}
          redirectToken={redirectToken}
        />
      )}
    </>
  );
}
