import useMentorHistory from '../../../../hooks/api/mentors/useMentorHistory';
import HistoryItem from './history-item';
import DateRangePicker from 'rsuite/DateRangePicker';
import Markdown from '../../../markdown/Markdown';
import 'rsuite/DateRangePicker/styles/index.css';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import ChatHistoryPagination from './history-pagination';
import useChatHistoryPagination from '../../../../hooks/api/mentors/useChatHistoryPagination';
import IBLPagination from '../../../pagination/IBLPagination';
import { usePageNumberPagination } from '../../../../hooks/api/mentors/usePageNumberPagination';

export default function History() {
  const {
    data,
    filters,
    loading,
    selected,
    setSelected,
    topicsRef,
    sentimentScoreRef,
    showTopicsMenu,
    showSentimentScoreMenu,
    setShowSentimentScoreMenu,
    setShowTopicsMenu,
    handleTopicChange,
    selectedTopics,
    setDateRange,
    setSentimentScore,
    sentimentScore,
    handleExportMessages,
    setPage,
  } = useMentorHistory();
  const history = data?.results?.filter((item) => item?.messages?.length > 0);

  const { page, numPages } = usePageNumberPagination(data);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const sentimentScores = ['Negative', 'Neutral', 'Positive'];

  return (
    <div className="tabs-content-2 w-tab-content" style={{ width: '100%' }}>
      <div
        data-w-tab="Tab 7"
        className="w-tab-pane w--tab-active"
        id="w-tabs-1-data-w-pane-4"
        role="tabpanel"
        aria-labelledby="w-tabs-1-data-w-tab-4"
        style={{
          opacity: 1,
          transition: 'opacity 300ms ease 0s',
        }}
      >
        <div
          react-component="ConversationLeftSideContainer"
          className="w-layout-vflex conversation-left-side-settings"
        >
          <div
            react-component="ConversationHeader"
            className="w-layout-vflex flex-block-179"
            style={{ display: 'none' }}
          >
            <h5 className="heading-43">Filters</h5>
            <div className="select-agent-form-block w-form">
              <form
                id="email-form-8"
                name="email-form-8"
                data-name="Email Form 8"
                method="get"
                className="select-agent-form"
                data-wf-page-id="66953e194f60e4f88496390e"
                data-wf-element-id="d4e0c217-a25d-b69f-19ea-c2871878d5b5"
                aria-label="Email Form 8"
              >
                <div className="w-layout-hflex flex-block-128">
                  <div className="w-layout-hflex flex-block-129 no-gap-sm">
                    <div className="w-layout-hflex conversation-date-selector-block no-border-radius-right no-border-left-sm">
                      <div>
                        <DateRangePicker
                          onChange={(value) => {
                            if (value?.length > 1) {
                              setDateRange({
                                startDate: value[0].toISOString().split('T')[0],
                                endDate: value[1].toISOString().split('T')[0],
                              });
                            } else {
                              setDateRange({ startDate: '', endDate: '' });
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-layout-vflex custom-select-parent-container _100w-mobile">
                      <div className="custom-select-block">
                        <input
                          className="input-field custom-select full-height bold left-borderless right-borderless _100w-mobile whitesmoke-bg w-input"
                          maxLength="256"
                          name="name-2"
                          data-name="Name 2"
                          onChange={(event) =>
                            setSentimentScore(event.target.value)
                          }
                          value={sentimentScore}
                          react-component="ConfidenceScoreInput"
                          placeholder="Sentiment Score"
                          onClick={() => {
                            setTimeout(() => {
                              setShowSentimentScoreMenu(
                                !showSentimentScoreMenu
                              );
                            }, 100);
                          }}
                          type="text"
                          id="name-2"
                        />
                        <img
                          loading="lazy"
                          src="https://cdn.prod.website-files.com/66953e194f60e4f88496390d/66e9a2dfc77a9ebbe061152a_chevrons-up-down%20(1).svg"
                          alt=""
                          className="custom-select-icon"
                        />
                      </div>
                      <div className="llm-dropdown-block-2">
                        <div
                          data-delay="0"
                          data-hover="false"
                          className="base-model-dropdown w-dropdown"
                        >
                          <div
                            className="base-model-dropdown-toggle w-dropdown-toggle"
                            id="w-dropdown-toggle-29"
                            aria-controls="w-dropdown-list-29"
                            aria-haspopup="menu"
                            aria-expanded="false"
                            role="button"
                            tabIndex="0"
                          >
                            <div
                              className="w-icon-dropdown-toggle"
                              aria-hidden="true"
                            ></div>
                            <div>Dropdown</div>
                          </div>
                          <nav
                            className={`base-model-dropdown-list w-dropdown-list ${showSentimentScoreMenu ? 'w--open' : ''}`}
                            ref={sentimentScoreRef}
                            id="w-dropdown-list-29"
                            aria-labelledby="w-dropdown-toggle-29"
                          >
                            <div className="w-layout-vflex base-model-dropdown-container">
                              <h6 className="heading-44">BASE MODELS</h6>
                              {sentimentScores?.map((item) => (
                                <div
                                  key={item}
                                  onClick={() => setSentimentScore(item)}
                                  className="base-model-dropdown-element"
                                >
                                  {item}
                                </div>
                              ))}
                            </div>
                          </nav>
                        </div>
                      </div>
                    </div>
                    <div className="w-layout-vflex custom-select-parent-container">
                      <div
                        data-delay="0"
                        data-hover="false"
                        className="transcript-filter-grid-dropdown light-secondary-hover-in-dark-mode w-dropdown"
                      >
                        <div
                          react-component="SourceSelectorDropDown"
                          onClick={() => {
                            setTimeout(() => {
                              setShowTopicsMenu(!showTopicsMenu);
                            }, 100);
                          }}
                          className="transcript-filter-grid-dropdown-toggle w-dropdown-toggle"
                          id="w-dropdown-toggle-30"
                          aria-controls="w-dropdown-list-30"
                          aria-haspopup="menu"
                          aria-expanded="false"
                          role="button"
                          tabIndex="0"
                        >
                          <div
                            className="transcript-filter-grid-dropdown-icon w-icon-dropdown-toggle"
                            aria-hidden="true"
                          ></div>
                          <div className="transcript-filter-grid-dropdown-text">
                            Topics
                          </div>
                        </div>
                        <nav
                          className={`transcript-filter-grid-dropdown-list w-dropdown-list ${showTopicsMenu ? 'w--open' : ''}`}
                          id="w-dropdown-list-30"
                          ref={topicsRef}
                          aria-labelledby="w-dropdown-toggle-30"
                        >
                          <div
                            className="w-layout-vflex transcript-filter-grid-dropdown-element-container light-dark-bg-in-dark-mode"
                            style={{ maxHeight: '240px', overflowY: 'auto' }}
                          >
                            {filters?.topics?.map((item) => (
                              <label
                                key={item.name}
                                className="w-checkbox transcript-filter-grid-checkbox-field"
                              >
                                <input
                                  type="checkbox"
                                  name={item.name}
                                  id="checkbox-3"
                                  data-name="Checkbox 3"
                                  onChange={handleTopicChange}
                                  className="w-checkbox-input transcript-filter-grid-checkbox"
                                  checked={selectedTopics.includes(item.name)}
                                />
                                <span
                                  className="transcript-filter-grid-checkbox-label w-form-label"
                                  htmlFor="checkbox-3"
                                >
                                  {item.name}
                                </span>
                              </label>
                            ))}
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                  <div className="w-layout-hflex flex-block-131">
                    <div
                      react-component="ExportButton"
                      onClick={handleExportMessages}
                      className="w-layout-hflex conversation-export-btn-block"
                    >
                      <div>Export</div>
                      <img
                        loading="lazy"
                        src="https://cdn.prod.website-files.com/6688489469d61d5309b10211/6688489469d61d5309b102af_download%20(1).svg"
                        alt=""
                        className="image-69"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div
            style={{ display: 'none' }}
            className="conversation-seperator-block"
          ></div>

          {data?.results?.length === 0 && (
            <div style={{ width: '100%', textAlign: 'center' }}>
              <i>No records</i>
            </div>
          )}

          <div className="w-layout-hflex conversation-filter-content-block">
            <div className="w-layout-vflex flex-block-183">
              <div className="w-layout-vflex conversation-filter-left-side-block">
                {loading && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '5px',
                    }}
                  >
                    <IBLSpinLoader size={30} />
                  </div>
                )}
                {!loading &&
                  history?.map((item, i) => (
                    <HistoryItem
                      item={item}
                      key={item.id}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  ))}
              </div>

              {data && (
                <IBLPagination
                  totalPages={numPages}
                  currentPage={page}
                  onPageChange={handlePageChange}
                />
              )}
            </div>

            {data?.results?.length > 0 && (
              <div
                react-component="ConversationFliterRightSideContainer"
                className="w-layout-vflex conversation-filter-right-side-block"
              >
                <h5 className="heading-45" style={{ display: 'none' }}>
                  Source: Widget or Iframe
                </h5>
                <div className="w-layout-vflex flex-block-132">
                  {selected?.messages?.map((msg, i) => (
                    <>
                      <div className="w-layout-hflex conversation-pill-block right-sided">
                        <div className="w-layout-hflex conversation-pill user-prompt">
                          <div>{msg?.human}</div>
                        </div>
                      </div>
                      <div className="w-layout-hflex conversation-pill-block">
                        <div className="w-layout-hflex conversation-pill">
                          <div>
                            <Markdown>{msg?.ai}</Markdown>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
